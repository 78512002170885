<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <router-link
        :to="{ name: 'profile-career' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Добавление места работы</h1>
    </div>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="org_text" label="Место работы (организация)" required>
            <template #additional>
              <app-checkbox
                v-model="org_not_in_rf"
                label="не в РФ"
                @input.native="onChangeNotInRF"
              />
            </template>
            <template v-if="!org_not_in_rf">
              <v-select
                label="name"
                v-model="form.org"
                :filterable="false"
                :options="org_options"
                :clearable="false"
                @search="onOrgSearch"
                @input="onSetOrg"
                placeholder="Введите название организации"
                class="select"
                :class="{ 'select--error': $v.form.org_text.$error }"
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template slot="no-options">введите для поиска название организации..</template>
                <template slot="option" slot-scope="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
            </template>
            <template v-else>
              <app-input
                id="org_text"
                name="org"
                v-model="form.org_text"
                @change.native="$v.form.org_text.$touch()"
                :error="$v.form.org_text.$error"
                placeholder="Введите название организации"
              />
            </template>
            <template #error>
              <div v-if="$v.form.org_text.$dirty && !$v.form.org_text.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="org" label="Адрес места работы" required>
            <template v-if="!org_not_in_rf">
              <v-select
                label="address"
                v-model="form.org_address"
                :reduce="address => address.address"
                :filterable="false"
                :options="org_address_options"
                :clearable="false"
                @search="onOrgAddressSearch"
                @change.native="$v.form.org_address.$touch()"
                placeholder="Введите адрес организации"
                class="select"
                :class="{ 'select--error': $v.form.org_address.$error }"
              >
                <template slot="open-indicator">
                  <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                    <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </template>
                <template slot="no-options">введите для поиска адрес...</template>
                <template slot="option" slot-scope="option">
                  <div class="select__item d-center">{{ option.address }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">{{ option.address }}</div>
                </template>
              </v-select>
            </template>
            <template v-else>
              <app-input
                id="org"
                name="org"
                v-model.trim="form.org_address"
                @change.native="$v.form.org_address.$touch()"
                :error="$v.form.org_address.$error"
                placeholder="Введите адрес организации"
              />
            </template>
            <template #error>
              <div v-if="$v.form.org_address.$dirty && !$v.form.org_address.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="position" label="Должность" required>
            <app-input
              id="position"
              name="position"
              v-model.trim="form.position"
              @change.native="$v.form.position.$touch()"
              :error="$v.form.position.$error"
              placeholder="Введите должность"
            />
            <template #error>
              <div v-if="$v.form.position.$dirty && !$v.form.position.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_start" label="Дата начала работы" required>
            <app-input
              @paste.native.prevent
              v-model="form.date_start"
              v-mask="'99.99.9999'"
              id="date_start"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              :error="$v.form.date_start.$error"
            />
            <template #error>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">Обязательное поле</div>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
          <app-form-group label-for="date_end" label="Дата окончания работы">
            <app-input
              @paste.native.prevent
              v-model="form.date_end"
              v-mask="'99.99.9999'"
              id="date_end"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              :error="$v.form.date_end.$error"
            />
            <template #error>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.underscorePresent">Заполните поле полностью</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label="Сфера деятельности" required>
            <v-select
              label="name"
              :options="field_activity_options"
              v-model="form.field_activity"
              :reduce="field_activity => field_activity.id"
              :searchable="false"
              class="select"
              :class="{ 'select--error': $v.form.field_activity.$error }"
              placeholder="Выберите значение"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item">{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.field_activity.$dirty && !$v.form.field_activity.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="desc" label="Обязанности">
            <app-textarea
              id="desc"
              name="desc"
              v-model.trim="form.desc"
              placeholder="Опишите Ваши обязанности"
            />
          </app-form-group>
          <app-form-group>
            <app-checkbox
              v-model="form.is_main"
              label="Основное место работы"
            />
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Добавить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { debounce, omit } from 'lodash'
const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'ProfileCareerCreate',
  validations: {
    form: {
      org_text: { required },
      org_address: { required },
      position: { required },
      date_start: { required, underscorePresent },
      date_end: { underscorePresent },
      field_activity: { required },
    },
  },
  data() {
    return {
      form: {
        org_text: null,
        org_address: null
      },
      org_options: [],
      org_address_options: [],
      field_activity_options: [],
      org_not_in_rf: false,
    }
  },
  mounted() {
    this.$store.dispatch('dict/GET_FIELD_ACTIVITY')
      .then(response => {
        this.field_activity_options = response.data
      })
  },
  methods: {
    onOrgSearch(search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ORG', {search}).then(response => {
        vm.org_options = response.data
        loading(false)
      })
    }, 350),
    onOrgAddressSearch (search, loading) {
      loading(true)
      this.searchOrgAddress(loading, search, this)
    },
    searchOrgAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('search/GET_ADDRESS', {search}).then(response => {
        vm.org_address_options = response.data
        loading(false)
      })
    }, 350),
    onSetOrg() {
      this.form.org_text = this.form.org.name
      this.form.org_address = this.form.org.address
      this.$v.form.org_text.$touch()
    },
    onChangeNotInRF() {
      this.form.org = null
      this.form.org_text = ''
      this.form.org_address = ''
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      if (!this.form.date_end) {
        this.form = omit(this.form, ['date_end'])
      }
      this.$refs.submit.preload = true
      this.$store.dispatch('profile/career/POST_DATA', this.form)
        .then(() => {
          this.$refs.submit.preload = false
          this.$router.push({ name: 'profile-career' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Данные о месте работы успешно добавлены.'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  },
}
</script>
